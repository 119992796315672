import React, { useEffect, useState } from 'react'
import { FaBars, FaKey, FaPlus, FaTimes, FaUser } from 'react-icons/fa'
import { Link, NavLink, useLocation } from 'react-router-dom'

const Header = ({thisUi, setThisUi, appState, setAppState, selected, setSelected}) => {
  const links = [
    {
      title: "home",
      to: "/",
      setTo: "",
      onClick: ()=>{
        setSelected("")
      }
    },
    {
      title: "escorts",
      to: "/",
      setTo: "escort",
      onClick: ()=>{
        setSelected("escort")
      }
    },
    {
      title: "massage",
      to: "/",
      setTo: "masseuse",
      onClick: ()=>{
        setSelected("masseuse")
      }
    },
    {
      title: "download",
      to: "/download",
      comingSoon: true,
      message: "Our Mobile App is in Development. Thank you"
    },
  ]
  const location = useLocation()
  const {pathname} = location
  const [showMenu, setShowMenu] = useState(false)
  const logout = appState?.functions?.logout
  useEffect(()=>{
    setShowMenu(false)
  },[appState, pathname])
  return (
    <>
      <div className={`menux links-holder ${showMenu ? "show-menu" : ""}`}>
          {
                  links?.map((link, index)=>{
                    return (
                      <Link key={index} to={link.comingSoon ? link.to : "/"} className={`${link.isBig ? "big" : ""} ${(pathname === "/" && selected === link.setTo) ? "active" : (pathname !== "/" ? (pathname === link.to ? "active" : "") : "")}`} onClick={()=>{
                        setShowMenu(false)
                         if(link.comingSoon){
                          alert(link.message)
                         }
                         if(link?.onClick){
                          link.onClick()
                         }
                      }}  disabled={link.comingSoon} style={{
                        opacity: link?.comingSoon ? "0.4" : "1",
                        pointerEvents: link?.comingSoon ? "none" : "1"
                      }}>
                        {link.title}
                      </Link>
                    )
                  })
                }
                {!appState?.isLoggedIn ? <>
                  <NavLink to={"/register"}>
                    Register
                  </NavLink>
                  <NavLink to={"/login"}>
                    Login
                  </NavLink>
                </> : <>
                <NavLink to={"/profile"}>
                  My Profile
                </NavLink>
                <Link onClick={()=>{
                  logout()
                }}>
                  Log out
                </Link>
              </>}
          </div>
      <header className='header'>
        <div className='container'>
          <div className='top'>
            <Link to={"/"} className='logo-el'></Link>
            <div className='advert-img'></div>
            <div className='mid'>
              <div className='links-holder'>
                {
                  links?.map((link, index)=>{
                    return (
                      <Link key={index} to={link.comingSoon ? link.to : "/"} className={`${link.isBig ? "big" : ""} ${(pathname === "/" && selected === link.setTo) ? "active" : (pathname !== "/" ? (pathname === link.to ? "active" : "") : "")}`} onClick={()=>{
                        if(link.comingSoon){
                          alert(link.message)
                         }
                         if(link?.onClick){
                          link.onClick()
                         }
                      }} disabled={link.comingSoon} style={{
                        opacity: link?.comingSoon ? "0.4" : "1",
                        pointerEvents: link?.comingSoon ? "none" : "1"
                      }}>
                        {link.title}
                      </Link>
                    )
                  })
                }
              </div>
              {!appState?.isLoggedIn ? <div className='links-holder'>
                <Link className='big' to={"/register"}>
                  <FaUser />Register
                </Link>
                <Link className='big' to={"/login"}>
                  <FaKey />Login
                </Link>
              </div> : <div className='links-holder'>
                <NavLink to={"/profile"}>
                  My Profile
                </NavLink>
                <Link onClick={()=>{
                  logout()
                }}>
                  Log out
                </Link>
              </div>}
              <div className={`hamburger ${showMenu ? "show-menu" : ""}`} onClick={()=>{
                setShowMenu(!showMenu)
              }}>
                {!showMenu ? <FaBars /> : <FaTimes />}
              </div>
            </div>
          </div>
          <div className={`menu links-holder ${showMenu ? "show-menu" : ""}`}>
          {
                  links?.map((link, index)=>{
                    return (
                      <Link key={index} to={link.comingSoon ? link.to : "/"} className={`${link.isBig ? "big" : ""} ${(pathname === "/" && selected === link.setTo) ? "active" : (pathname !== "/" ? (pathname === link.to ? "active" : "") : "")}`} onClick={()=>{
                        setShowMenu(false)
                         if(link.comingSoon){
                          alert(link.message)
                         }
                         if(link?.onClick){
                          link.onClick()
                         }
                      }}  disabled={link.comingSoon} style={{
                        opacity: link?.comingSoon ? "0.4" : "1",
                        pointerEvents: link?.comingSoon ? "none" : "1"
                      }}>
                        {link.title}
                      </Link>
                    )
                  })
                }
                {!appState?.isLoggedIn ? <>
                  <NavLink to={"/register"}>
                    Register
                  </NavLink>
                  <NavLink to={"/login"}>
                    Login
                  </NavLink>
                </> : <>
                <NavLink to={"/profile"}>
                  My Profile
                </NavLink>
                <Link onClick={()=>{
                  logout()
                }}>
                  Log out
                </Link>
              </>}
          </div>
          {thisUi?.showOnlinePrompt && <div className='bottom'>
            <button className='btn full-w'>
              See who is online - <span className='circle green'></span>
            </button>
            <button className='close' onClick={()=>{
              setThisUi((prev)=>{
                return({
                  ...prev,
                  showOnlinePrompt: false,
                })
              })
            }}>
              <FaTimes />
            </button>
          </div>}
          <div className='floating' style={{
            display: "none"
          }}>
            <div className='float left'>
              <div className='heading'>
                See Escorts from
              </div>
              <div className='selector'>
                <div className='select'>
                  <FaPlus /> Abuja
                </div>
                <div className='select'>
                  <FaPlus /> Delta
                </div>
                <div className='select'>
                  <FaPlus /> Imo State
                </div>
                <div className='select'>
                  <FaPlus /> Lagos</div>
              </div>
            </div>
            <div className='float right'>
              <div className='heading'>
                Quick Search <span><FaTimes /></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header