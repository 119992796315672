import React from 'react'
import { MdLocationOn } from 'react-icons/md'
import { Link } from 'react-router-dom'

const EscortCard = ({escort, user, isVip, isPremium, appState}) => {
    // const phoneShort = user?.phoneNo?.slice ? `${user?.phoneNo?.slice(0, 10)}XXXX` : user?.phoneNo
    const subscribed = appState?.isLoggedIn
    // const services = user?.services || []
    return (
    <>
        <div className='escort--card'>
            {user.isVip && <div className='vip  red'>vip</div>}
            {user.isPremium && <div className='premium'>premium</div>}
            <div className='top'>
            <img src={user?.imgUrl || "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png"} alt='' />
            <div className='details'>
                <h3>{user.name}</h3>
                {user?.location && <p>
                <MdLocationOn /> {user?.location?.city}, {user?.location?.state}
                </p>}
            </div>
            </div>
            <Link to={subscribed ? `/user-profile/${user?.uid}` : `/user-profile/${user?.uid}`} className='btn greenbutton hover'>
            {subscribed ? "View Profile" : "View Profile"}
            </Link>
            {/* <Link to={subscribed ? `https://wa.me/${user?.phoneNo}` : "/register"} className='btn greenbutton hover' target={subscribed ? "_blank" : "_self"} onClick={()=>{
                if(!subscribed){
                    alert("Create an Account to Contact Escorts")
                }
            }}>
            {subscribed ? (user?.phoneNo || "...") : (phoneShort || "...")}
            </Link> */}
            <div className='bio full-w'>
                {/* <h3 style={{
                    fontSize: "22px",
                }}>{user.name}</h3> */}
                {<p style={{
                    // marginTop:"20px",
                    marginBottom: "-5px",
                    fontWeight: "700",
                    fontSize: "18px",
                }}>
                    My Bio
                </p>}
                <p  style={{
                    // marginTop:"20px",
                    // marginBottom: "-5px",
                    // fontWeight: "00",
                    fontSize: "14px",
                }}>
                    {user.bio || "Escort on Massageandx.com"}
                </p>
                {/* {services?.length > 0 && <p style={{
                    marginTop:"20px",
                    marginBottom: "5px",
                    fontWeight: "700",
                    fontSize: "18px",
                }}>
                    My Services
                </p>}
                {services?.length > 0 && <div className='services-min'>
                    {
                        services?.map((service, index)=>{
                            return (
                                <div className='service-min' key={index}>
                                    {service}
                                </div>
                            )
                        })
                    }
                </div>} */}
            </div>
        </div>
    </>
  )
}

export default EscortCard