import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div className='max-width'>
            © 2024 MassageandX.com
        </div>
    </footer>
  )
}

export default Footer