import React, { useLayoutEffect, useRef, useState } from 'react'
import { FaChevronUp, FaPlus, FaUser } from 'react-icons/fa';
import Footer from '../../components/app/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebaseConfig';

const Profile = ({thisUi, setAppState, appState, selected, users, setSelected}) => {

    const pageRef = useRef(null)

  const scrollTop = ()=>{
    pageRef?.current?.scrollTo(0, 0)
  }

  
  const [showScrollTop, setScrollTop] = useState(false)

  useLayoutEffect(()=>{
    const page = pageRef?.current
    // const page = document.getElementById("page")
    function checkScroll(){
      if(page?.scrollTop && page?.scrollTop > 100){
        setScrollTop(true)
      } else{
        setScrollTop(false)
      }
    }

    if(page){
      page.addEventListener("scroll", checkScroll)
    }
  }, [])

  const applyAsEscort = async ()=>{
    const details = appState?.user
    try{
        setAppState((prev)=>{
            return(
                {
                    ...prev,
                    isLoading: true,
                }
            )
        })
        const userDoc = doc(firestore, 'users', auth.currentUser.uid);
        await updateDoc(userDoc, {
            ...details,
            isVisible: true,
        });
        setAppState((prev)=>{
            return(
                {
                    ...prev,
                    isLoading: false,
                }
            )
        })
        setTimeout(()=>{
            window.location.reload()
        },500)
    } catch(error){
        console.error(error)
    }
  }

  const revokeApplication = async ()=>{
    const details = appState?.user
    try{
        setAppState((prev)=>{
            return(
                {
                    ...prev,
                    isLoading: true,
                }
            )
        })
        const userDoc = doc(firestore, 'users', auth.currentUser.uid);
        await updateDoc(userDoc, {
            ...details,
            isVisible: false,
        });
        setAppState((prev)=>{
            return(
                {
                    ...prev,
                    isLoading: false,
                }
            )
        })
        setTimeout(()=>{
            window.location.reload()
        },500)
    } catch(error){
        console.error(error)
        setAppState((prev)=>{
            return(
                {
                    ...prev,
                    isLoading: false,
                }
            )
        })
    }
  }

  const user = appState.user
  const navigate = useNavigate()
  return (
    <>
        <div className='page profile--page home--page' ref={pageRef}>
            <div className={`scroll-to-top ${showScrollTop ? "show" : ""}`} onClick={scrollTop}>
              <FaChevronUp />
            </div>
            <div className='container'>
                <section className='head-section'>
                    <h2>
                        Profile
                    </h2>
                    <div className='profile-actions'>
                        {(user?.accountType !== "user" && !user?.imgUrl) && <div className='completion'>
                            <p>
                                Add a profile photo to get listed on the website
                            </p>
                            <div className='detail'>
                                <p style={{
                                    width: `${80}%`
                                }}></p>
                            </div>
                        </div>}
                        <div className='info xyz' style={{
                            display: "none"
                        }}>
                            <>
                                {!appState?.user?.isVisible && <button className={`btn ${user?.calculatedPercent === "100" ? "active" : ""}`} onClick={()=>{
                                    if(user?.calculatedPercent === "100"){
                                        applyAsEscort()
                                    } else{
                                        const confirm  = window.confirm("Complete your Profile to Apply")
                                        if(confirm){
                                            navigate("/update-profile")
                                        }
                                    }
                                }}>
                                    Apply as an Escort
                                </button>}
                                {appState?.user?.isVisible && <button className={`btn ${user?.calculatedPercent === "100" ? "active" : ""}`} onClick={()=>{
                                    if(user?.calculatedPercent === "100"){
                                        revokeApplication()
                                    } else{
                                        const confirm  = window.confirm("Complete your Profile to Apply")
                                        if(confirm){
                                            navigate("/update-profile")
                                        }
                                    }
                                }}>
                                    Revoke Application
                                </button>}
                                <button className={`btn`} onClick={()=>{
                                    if(user?.calculatedPercent === "100"){

                                    } else{
                                        const confirm  = window.confirm("Complete your Profile to Apply")
                                        if(confirm){
                                            navigate("/update-profile")
                                        }
                                    }
                                }}>
                                    Apply for VIP
                                </button>
                                <button className={`btn ${user?.calculatedPercent === "100" ? "active" : ""}`} onClick={()=>{
                                    if(user?.calculatedPercent === "100"){

                                    } else{
                                        const confirm  = window.confirm("Complete your Profile to Apply")
                                        if(confirm){
                                            navigate("/update-profile")
                                        }
                                    }
                                }}>
                                    Apply for Premium
                                </button>
                            </>
                        </div>
                        <p style={{
                            marginTop: "30px",
                            color:"#666"
                        }}>
                            <i>* means important</i>
                        </p>
                    </div>
                </section>
                <section className='profile--section' style={{
                    marginTop: "-30px",
                }}>
                    <h3>Profile Photo*</h3>
                    <div className='img-holder'>
                        {user?.imgUrl ? <img width={200} src={user.imgUrl} alt='' />
                        : <FaUser />}
                    </div>
                </section>
                {user?.accountType !== "user" && <section className='profile--section'>
                    <h3>Gallery</h3>
                    <div className='gallery-img-holder'>
                        <div className='gallery-img' onClick={()=>{
                            if(!user.gallery?.pic1Url){
                                navigate("/update-profile")
                            } 
                        }}>
                            {user.gallery?.pic1Url ? <img width={200} src={user.gallery?.pic1Url} alt='' /> : <FaPlus />}
                        </div>
                        <div className='gallery-img' onClick={()=>{
                            if(!user.gallery?.pic2Url){
                                navigate("/update-profile")
                            } 
                        }}>
                            {user.gallery?.pic2Url ? <img width={200} src={user.gallery?.pic2Url} alt='' /> : <FaPlus />}
                        </div>
                        <div className='gallery-img' onClick={()=>{
                            if(!user.gallery?.pic3Url){
                                navigate("/update-profile")
                            } 
                        }}>
                            {user.gallery?.pic3Url ? <img width={200} src={user?.gallery?.pic3Url} alt='' /> : <FaPlus />}
                        </div>
                    </div>
                </section>}
                <section className='profile--section'>
                    <h3>
                        My Details
                    </h3>
                    <div className='detail'>
                        <label>
                            Display Name*
                        </label>
                        <span className='content'>
                            {user?.name}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            First Name*
                        </label>
                        <span className='content'>
                            {user?.firstName}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            Last Name*
                        </label>
                        <span className='content'>
                            {user?.lastName}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            Bio*
                        </label>
                        <span className='content'>
                            {user?.bio}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            Email*
                        </label>
                        <span className='content'>
                            {user?.email}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            Age*
                        </label>
                        <span className='content'>
                            {user?.age || "18+"}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            Phone Number*
                        </label>
                        <span className='content'>
                            {user?.phoneNo || user.phone}
                        </span>
                    </div>
                </section>
                <section className='profile--section'>
                    <h3>
                        Location
                    </h3>
                    <div className='detail'>
                        <label>
                            Country*
                        </label>
                        <span className='content'>
                            {user?.location?.country}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            State*
                        </label>
                        <span className='content'>
                            {user?.location?.state}
                        </span>
                    </div>
                    <div className='detail'>
                        <label>
                            City
                        </label>
                        <span className='content'>
                            {user?.location?.city}
                        </span>
                    </div>
                </section>
                {user?.accountType !== "user" && <>
                    <section className='profile--section'>
                        <h3>
                            Services
                        </h3>
                        <div className='selector services' onClick={()=>{
                            navigate("/update-profile")
                        }}>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.eroticTantricMassage} name='eroticTantricMassage' />
                                                <p>
                                                    Erotic Tantric massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.deepTissueMassageTherapy} name='deepTissueMassageTherapy' />
                                                <p>
                                                    Deep Tissue Massage Theraoy
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.thaiMassage} name='thaiMassage' />
                                                <p>
                                                    Thai Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.massageSex} name='massageSex' />
                                                <p>
                                                    Massage, sex / extras / happy ending 
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.webcamSex} name='webcamSex' />
                                                <p>
                                                    Webcam sex (phone sex, video call) 
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.dfk} name='dfk' />
                                                <p>
                                                    DFK (Deep french kissing)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.oLevel} name='oLevel' />
                                                <p>
                                                    O-Level (Oral Sex)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.cob} name='cob' />
                                                <p>
                                                    COB (Come on body)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.lt} name='lt' />
                                                <p>
                                                    LT (Long Time; Usually overnight, sleep over)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.stripTease} name='stripTease' />
                                                <p>
                                                    Striptease/Lapdance
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.couples} name='couples' />
                                                <p>
                                                    Couples
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.teaBagging} name='teaBagging' />
                                                <p>
                                                    Teabagging
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.sixNine} name='sixNine' />
                                                <p>
                                                    (69 sex position/Reverse oral)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.vaginalSex} name='vaginalSex' />
                                                <p>
                                                    Vaginal Sex/pussy fuck
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.cof} name='cof' />
                                                <p>
                                                    COF (Come on face)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.deepThroat} name='deepThroat' />
                                                <p>
                                                    Deep Throat
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.squirting} name='squirting' />
                                                <p>
                                                    Squirting
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.straponDildo} name='straponDildo' />
                                                <p>
                                                    Strapon dildo
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.cim} name='cim' />
                                                <p>
                                                    CIM (come in mouth)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.analRimming} name='analRimming' />
                                                <p>
                                                    Anal Rimming (Licking anus)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.doublePenetration} name='doublePenetration' />
                                                <p>
                                                    Double Penetration
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.nuruMassage} name='nuruMassage' />
                                                <p>
                                                    Full Body to Body Nuru massage - Nude
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.swedishMassage} name='swedishMassage' />
                                                <p>
                                                    Swedish Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.professionalMassage} name='professionalMassage' />
                                                <p>
                                                    Professional Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.hotStoneMassaage} name='hotStoneMassaage' />
                                                <p>
                                                    Hot stone massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.romanticDinner} name='romanticDinner' />
                                                <p>
                                                    Romantic Dinner dates
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.travellingCompanion} name='travellingCompanion' />
                                                <p>
                                                    Travelling Companion
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.handJob} name='handJob' />
                                                <p>
                                                    Hand Job
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.blowJob} name='blowJob' />
                                                <p>
                                                    Blow Job (bj)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.masturbation} name='masturbation' />
                                                <p>
                                                    Masturbation
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.cumOnBoobs} name='cumOnBoobs' />
                                                <p>
                                                    Cum on breasts/boobs/tits
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.extraBall} name='extraBall' />
                                                <p>
                                                    Extraball (Having sex multiple times)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.gfe} name='gfe' />
                                                <p>
                                                    GFE (Girlfriend experience)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.domination} name='domination' />
                                                <p>
                                                    Domination
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.domination} name='domination' />
                                                <p>
                                                    Domination
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.threesome} name='threesome' />
                                                <p>
                                                    Threesome
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.lesbianSex} name='lesbianSex' />
                                                <p>
                                                    Lesbian sex/show
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.sexParties} name='sexParties' />
                                                <p>
                                                    Attending corporate Parties / sex parties - group sex
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.owo} name='owo' />
                                                <p>
                                                    OWO (Oral without condom)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.faceSitting} name='faceSitting' />
                                                <p>
                                                    Face sitting
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.goldenShower} name='goldenShower' />
                                                <p>
                                                    Golden shower
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.sexToys} name='sexToys' />
                                                <p>
                                                    Sex Toys
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.fantasies} name='fantasies' />
                                                <p>
                                                    Fantasies & Costumes
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.footFetish} name='footFetish' />
                                                <p>
                                                    Foot fetish
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.bdsm} name='bdsm' />
                                                <p>
                                                    BDSM (Bondage/Discipline, Dominance/Submission and Sadism/Masochism)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.pegging} name='pegging' />
                                                <p>
                                                    Pegging
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.aLevel} name='aLevel' />
                                                <p>
                                                    A-Level (Anal sex)/ Ass fuck
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.hardSports} name='hardSports' />
                                                <p>
                                                    Giving hardsports
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.rawSex} name='rawSex' />
                                                <p>
                                                    Raw Sex
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={()=>{

                                                }} checked={user?.manicurePedicure} name='manicurePedicure' />
                                                <p>
                                                    Manicure, Pedicure
                                                </p>
                                            </div>
                                        </div>
                    </section>
                    <section className='profile--section'>
                                        <label>
                                            Rates*
                                        </label>
                                        <p>
                                            Currency: <b>USD</b>
                                        </p>
                                        <div className='rates-holder' onClick={()=>{
                            navigate("/update-profile")
                        }}>
                                            <div className='top tab'>
                                                <div className='time'>
                                                    
                                                </div>
                                                <div className='time'>
                                                    Incall
                                                </div>
                                                <div className='time'>
                                                    Outcall
                                                </div>
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    30 minutes
                                                </div>
                                                <input className='incall' name='thirtyMinRateInCall' value={user?.thirtyMinRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='thirtyMinRateOutCall' value={user?.thirtyMinRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    1 hour
                                                </div>
                                                <input className='incall' name='oneHourRateInCall' value={user?.oneHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='oneHourRateOutCall' value={user?.oneHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    2 hours
                                                </div>
                                                <input className='incall' name='twoHourRateInCall' value={user?.twoHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='twoHourRateOutCall' value={user?.twoHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    3 hours
                                                </div>
                                                <input className='incall' name='threeHourRateInCall' value={user?.threeHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='threeHourRateOutCall' value={user?.threeHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    6 hours
                                                </div>
                                                <input className='incall' name='sixHourRateInCall' value={user?.sixHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='sixHourRateOutCall' value={user?.sixHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    12 hours
                                                </div>
                                                <input className='incall' name='twelveHourRateInCall' value={user?.twelveHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='twelveHourRateOutCall' value={user?.twelveHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    24 hours
                                                </div>
                                                <input className='incall' name='twentyFourHourRateInCall' value={user?.twentyFourHourRateInCall} onChange={()=>{

                                                }} />
                                                <input className='outcall' name='twentyFourHourRateOutCall' value={user?.twentyFourHourRateOutCall} onChange={()=>{

                                                }} />
                                            </div>
                                        </div>
                    </section>
                </>}
                <div className='btn-holder'>
                    <Link className="btn" to={"/update-profile"}>
                        Update Profile
                    </Link>
                </div>
                <Footer/>
            </div>
        </div>
    </>
  )
}

export default Profile