import React from 'react'
import peachImg from "../assets/peaches.png"
import eggPlantImg from "../assets/eggplant.png"
import heartImg from "../assets/heart.png"

const FixedEl = ({element}) => {
  return (
    <div className='fixed-el'>
        <img src={`${element === "peach" ? peachImg : (element === "eggplant" ? eggPlantImg : heartImg)}`} alt='' />
    </div>
  )
}

export default FixedEl