import React, { useState } from 'react'
import { IoIosPersonAdd } from 'react-icons/io'
import Footer from '../../../components/app/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../../../firebaseConfig';
import { useAuth } from '../../../context/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { FaArrowLeft, FaCheck, FaChevronRight, FaViber, FaWhatsapp } from 'react-icons/fa';

const RegisterEscort = ({appState, setAppState}) => {
    const [ userInfo, setUserInfo ] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: "",
        selectedRole: "Visitor"
    })

    const authUser = useAuth();
    const currentUser = authUser?.currentUser


    const registrationTypes = [
        {
            title:"Register as an independent Escort",
            id: "escort",
            accountType: "escort",
            features: [
                "Add a single profile",
                "Add profile pictures",
                "Add gallery pictures",
                "Add contact information",
                "Add tours",
                "Add blacklisted clients",
                "Post classified ads",
                "many more",
            ],
            price: "Free",
            linkTo: "/register/escort",
        },
        // {
        //     title:"Register as an Agency",
        //     id: "agency",
        //     features: [
        //         "Add escorts under a single account",
        //         "Add profile pictures",
        //         "Add contact information",
        //         "Add tours to profiles",
        //         "Add blacklisted clients",
        //         "many more",
        //     ],
        //     price: "Free",
        //     linkTo: "/register/massage",
        //     // moreInfo: "for 2 weeks",
        // },
        {
            title:"Register as Normal User",
            features: [
                "Mark favorite profiles",
                "See profile pictures",
                "Can contact escorts",
                "Can add reviews to escorts and rate them",
                "Post classified ads",
            ],
            id: "user",
            accountType: "user",
            price: "Free",
            linkTo: "/register/massage",
            // moreInfo: "for 2 weeks",
        },
    ]
    const type = registrationTypes[0]

    const [error, setError] = useState("")

    const [selected, setSelected] = useState(false)

    const [selectedDetails, setSelectedDetails] = useState(null)

    const handleChange = (e)=>{
        const {name, value} = e.target
        const type = e?.target?.type
        if(type === "checkbox"){
            const checked = e?.target?.checked
            setUserInfo((prev)=>{
                return (
                    {
                        ...prev,
                        [name]: checked
                    }
                )
            })
        } else{
            setUserInfo((prev)=>{
                return (
                    {
                        ...prev,
                        [name]: value
                    }
                )
            })
        }

        try{
            
        } catch(error){
            setError(error?.message)
        }
    }

    // const roles = [
    //     "Visitor",
    //     "Escort",
    //     "Massage",
    //     "Escort & Massage",
    // ]

    const navigate = useNavigate()

    const createUserProfileDocument = async (details, user) => {
        try{
            await setDoc(doc(firestore, "users", user?.uid), {
              ...details,
              accountType: selectedDetails?.accountType,
              uid: user?.uid
            });
            navigate('/profile'); // Navigate to dashboard after signup
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: false,
                    }
                )
            })
            console.log("Firestore user profile document created.");
        } catch(error){
            console.error(error)
            throw error
        }
    };

    const looks = [
        "Nothing Special",
        "Average",
        "Sexy",
        "Ultra Sexy",
    ]

    const levels = [
        "Minimal",
        "Conversational",
        "Fluent",
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        const details = {
            ...userInfo,
        }
        const {email, password, cpassword} = userInfo
        if(password === cpassword){
            if(userInfo?.selectedRole){
                try {
                    setAppState((prev)=>{
                        return(
                            {
                                ...prev,
                                isLoading: true,
                            }
                        )
                    })
                    const result = await createUserWithEmailAndPassword(auth, email, password);
                    const user = result.user
                    await createUserProfileDocument(details, user);
                } catch (error) {
                    console.error(error);
                    setAppState((prev)=>{
                        return(
                            {
                                ...prev,
                                isLoading: false,
                            }
                        )
                    })
                    setError(error.message)
                }
            } else{
                alert("Please complete all the necessary fields")
            }
        } else{
            alert("Passwords do not match.")
        }
    };

    return (
    <>
        <div className='page register--page no-bg centered'>
            <div className='container centered' style={{
                marginTop: "0px",
            }}>
                <section className='auth-section'>
                    {selected && <div className='back-btn' style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "500",
                        marginBottom: "20px",
                        marginTop: "-30px"
                    }}>
                        <button style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "600",
                        cursor: "pointer",
                        fontSize: "15px",
                        padding: "10px 15px",
                        border: "1px solid #0002",
                        borderRadius: "10px",
                    }} onClick={()=>{
                        setSelected(false)
                        setSelectedDetails(null)
                    }}>
                            <FaArrowLeft /> Back
                        </button>
                    </div>}
                    <div className='heading'>
                        <h2>{selectedDetails?.title || "Create an Account"}</h2>
                    </div>
                    {type?.moreInfo && <div className='more-info'>
                        <p>
                            {type?.moreInfo}
                        </p>
                    </div>}
                    {selected ? 
                        <>
                            {selectedDetails?.id === "escort" && <form className='form' onSubmit={handleSubmit}>
                                <p style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}>
                                    Fields marked with * are mandatory
                                </p>
                                <div className='inp-holder'>
                                    <label>
                                        Email*
                                    </label>
                                    <input required placeholder='email@address.com' value={userInfo?.email} name='email' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        First Name*
                                    </label>
                                    <input required placeholder='John' value={userInfo?.firstName} name='firstName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Last Name*
                                    </label>
                                    <input required placeholder='Doe' value={userInfo?.lastName} name='lastName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Display Name*
                                    </label>
                                    <input required placeholder='Johnny Doe' value={userInfo?.name} name='name' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Phone*
                                    </label>
                                    <input required placeholder='' value={userInfo?.phone} name='phone' onChange={handleChange} />
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Available on
                                    </label>
                                    <div className='selector'>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.availableOnWhatsapp} name='availableOnWhatsapp' />
                                            <p>
                                                <FaWhatsapp />
                                                Whatsapp
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.availableOnViber} name='availableOnViber' />
                                            <p>
                                                <FaViber />
                                                Viber
                                            </p>
                                        </div>
                                    </div>  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Website
                                    </label>
                                    <input placeholder='' value={userInfo?.website} name='website' type="url" onChange={handleChange} />
                                </div>

                                <div className='inp-holder'>
                                    <label>
                                        Instagram
                                    </label>
                                    <input placeholder='Instagram username' value={userInfo?.instagram} name='instagram' onChange={handleChange} />
                                </div>

                                <div className='inp-holder'>
                                    <label>
                                        Facebook
                                    </label>
                                    <input placeholder='Facebook profile/page url' value={userInfo?.facebook} type='url' name='facebook' onChange={handleChange} />
                                </div>

                                <div className='inp-holder'>
                                    <label>
                                        Looks*
                                    </label>
                                    <input placeholder='' value={userInfo?.looks} type='text' name='looks' />
                                    <div className='selector'>
                                        {
                                            looks?.map((look, index)=>{
                                                return (
                                                    <div className={`select ${look === userInfo?.looks ? "active" : ""}`} key={index} onClick={()=>{
                                                        setUserInfo((prev)=>{
                                                            return ({
                                                                ...prev,
                                                                looks: look,
                                                            })
                                                        })
                                                    }}>
                                                        {look}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Availability*
                                    </label>
                                    <div className='selector'>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.availabilityInCall} name='availabilityInCall' />
                                            <p>
                                                Incall
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.availabilityOutCall} name='availabilityOutCall' />
                                            <p>
                                                Outcall - home, hotel, apartment
                                            </p>
                                        </div>
                                    </div>  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Smoker*
                                    </label>
                                    <div className='selector'>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.smoker} name='smoker' />
                                            <p>
                                                Yes
                                            </p>
                                        </div>
                                    </div>  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        About you*
                                    </label>
                                    <textarea required type='text' min={18} placeholder='' value={userInfo?.bio} name='bio' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder' style={{
                                    marginTop: "20px",
                                }}>
                                    <label>
                                        Languages spoken
                                    </label>
                                    <div className='lang-selector'>
                                        <div className='lang'>
                                            <input name='langi' onChange={handleChange} />
                                            <div className='selector'>
                                                {
                                                    levels?.map((level, index)=>{
                                                        return (
                                                            <div className={`select ${level === userInfo?.langiLevel ? "active" : ""}`} key={index} onClick={()=>{
                                                                setUserInfo((prev)=>{
                                                                    return ({
                                                                        ...prev,
                                                                        langiLevel: level,
                                                                    })
                                                                })
                                                            }}>
                                                                {level}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='lang'>
                                            <input name='langii' onChange={handleChange} />
                                            <div className='selector'>
                                                {
                                                    levels?.map((level, index)=>{
                                                        return (
                                                            <div className={`select ${level === userInfo?.langiiLevel ? "active" : ""}`} key={index} onClick={()=>{
                                                                setUserInfo((prev)=>{
                                                                    return ({
                                                                        ...prev,
                                                                        langiiLevel: level,
                                                                    })
                                                                })
                                                            }}>
                                                                {level}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='lang'>
                                            <input name='langiii' onChange={handleChange} />
                                            <div className='selector'>
                                                {
                                                    levels?.map((level, index)=>{
                                                        return (
                                                            <div className={`select ${level === userInfo?.langiiiLevel ? "active" : ""}`} key={index} onClick={()=>{
                                                                setUserInfo((prev)=>{
                                                                    return ({
                                                                        ...prev,
                                                                        langiiiLevel: level,
                                                                    })
                                                                })
                                                            }}>
                                                                {level}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Rates*
                                    </label>
                                    <small>at least one rate</small>
                                    <p>
                                        Currency: <b>USD</b>
                                    </p>
                                    <div className='rates-holder'>
                                        <div className='top tab'>
                                            <div className='time'>
                                                
                                            </div>
                                            <div className='time'>
                                                Incall
                                            </div>
                                            <div className='time'>
                                                Outcall
                                            </div>
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                30 minutes
                                            </div>
                                            <input className='incall' name='thirtyMinRateInCall' value={userInfo?.thirtyMinRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='thirtyMinRateOutCall' value={userInfo?.thirtyMinRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                1 hour
                                            </div>
                                            <input className='incall' name='oneHourRateInCall' value={userInfo?.oneHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='oneHourRateOutCall' value={userInfo?.oneHourRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                2 hours
                                            </div>
                                            <input className='incall' name='twoHourRateInCall' value={userInfo?.twoHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='twoHourRateOutCall' value={userInfo?.twoHourRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                3 hours
                                            </div>
                                            <input className='incall' name='threeHourRateInCall' value={userInfo?.threeHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='threeHourRateOutCall' value={userInfo?.threeHourRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                6 hours
                                            </div>
                                            <input className='incall' name='sixHourRateInCall' value={userInfo?.sixHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='sixHourRateOutCall' value={userInfo?.sixHourRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                12 hours
                                            </div>
                                            <input className='incall' name='twelveHourRateInCall' value={userInfo?.twelveHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='twelveHourRateOutCall' value={userInfo?.twelveHourRateOutCall} onChange={handleChange} />
                                        </div>
                                        <div className='content tab'>
                                            <div className='time'>
                                                24 hours
                                            </div>
                                            <input className='incall' name='twentyFourHourRateInCall' value={userInfo?.twentyFourHourRateInCall} onChange={handleChange} />
                                            <input className='outcall' name='twentyFourHourRateOutCall' value={userInfo?.twentyFourHourRateOutCall} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Services*
                                    </label>
                                    <div className='selector services'>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.eroticTantricMassage} name='eroticTantricMassage' />
                                            <p>
                                                Erotic Tantric massage
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.deepTissueMassageTherapy} name='deepTissueMassageTherapy' />
                                            <p>
                                                Deep Tissue Massage Theraoy
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.thaiMassage} name='thaiMassage' />
                                            <p>
                                                Thai Massage
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.massageSex} name='massageSex' />
                                            <p>
                                                Massage, sex / extras / happy ending 
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.webcamSex} name='webcamSex' />
                                            <p>
                                                Webcam sex (phone sex, video call) 
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.dfk} name='dfk' />
                                            <p>
                                                DFK (Deep french kissing)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.oLevel} name='oLevel' />
                                            <p>
                                                O-Level (Oral Sex)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.cob} name='cob' />
                                            <p>
                                                COB (Come on body)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.lt} name='lt' />
                                            <p>
                                                LT (Long Time; Usually overnight, sleep over)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.stripTease} name='stripTease' />
                                            <p>
                                                Striptease/Lapdance
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.couples} name='couples' />
                                            <p>
                                                Couples
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.teaBagging} name='teaBagging' />
                                            <p>
                                                Teabagging
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.sixNine} name='sixNine' />
                                            <p>
                                                (69 sex position/Reverse oral)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.vaginalSex} name='vaginalSex' />
                                            <p>
                                                Vaginal Sex/pussy fuck
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.cof} name='cof' />
                                            <p>
                                                COF (Come on face)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.deepThroat} name='deepThroat' />
                                            <p>
                                                Deep Throat
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.squirting} name='squirting' />
                                            <p>
                                                Squirting
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.straponDildo} name='straponDildo' />
                                            <p>
                                                Strapon dildo
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.cim} name='cim' />
                                            <p>
                                                CIM (come in mouth)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.analRimming} name='analRimming' />
                                            <p>
                                                Anal Rimming (Licking anus)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.doublePenetration} name='doublePenetration' />
                                            <p>
                                                Double Penetration
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.nuruMassage} name='nuruMassage' />
                                            <p>
                                                Full Body to Body Nuru massage - Nude
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.swedishMassage} name='swedishMassage' />
                                            <p>
                                                Swedish Massage
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.professionalMassage} name='professionalMassage' />
                                            <p>
                                                Professional Massage
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.hotStoneMassaage} name='hotStoneMassaage' />
                                            <p>
                                                Hot stone massage
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.romanticDinner} name='romanticDinner' />
                                            <p>
                                                Romantic Dinner dates
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.travellingCompanion} name='travellingCompanion' />
                                            <p>
                                                Travelling Companion
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.handJob} name='handJob' />
                                            <p>
                                                Hand Job
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.blowJob} name='blowJob' />
                                            <p>
                                                Blow Job (bj)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.masturbation} name='masturbation' />
                                            <p>
                                                Masturbation
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.cumOnBoobs} name='cumOnBoobs' />
                                            <p>
                                                Cum on breasts/boobs/tits
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.extraBall} name='extraBall' />
                                            <p>
                                                Extraball (Having sex multiple times)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.gfe} name='gfe' />
                                            <p>
                                                GFE (Girlfriend experience)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.domination} name='domination' />
                                            <p>
                                                Domination
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.domination} name='domination' />
                                            <p>
                                                Domination
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.threesome} name='threesome' />
                                            <p>
                                                Threesome
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.lesbianSex} name='lesbianSex' />
                                            <p>
                                                Lesbian sex/show
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.sexParties} name='sexParties' />
                                            <p>
                                                Attending corporate Parties / sex parties - group sex
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.owo} name='owo' />
                                            <p>
                                                OWO (Oral without condom)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.faceSitting} name='faceSitting' />
                                            <p>
                                                Face sitting
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.goldenShower} name='goldenShower' />
                                            <p>
                                                Golden shower
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.sexToys} name='sexToys' />
                                            <p>
                                                Sex Toys
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.fantasies} name='fantasies' />
                                            <p>
                                                Fantasies & Costumes
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.footFetish} name='footFetish' />
                                            <p>
                                                Foot fetish
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.bdsm} name='bdsm' />
                                            <p>
                                                BDSM (Bondage/Discipline, Dominance/Submission and Sadism/Masochism)
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.pegging} name='pegging' />
                                            <p>
                                                Pegging
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.aLevel} name='aLevel' />
                                            <p>
                                                A-Level (Anal sex)/ Ass fuck
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.hardSports} name='hardSports' />
                                            <p>
                                                Giving hardsports
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.rawSex} name='rawSex' />
                                            <p>
                                                Raw Sex
                                            </p>
                                        </div>
                                        <div className='select'>
                                            <input type="checkbox" onChange={handleChange} checked={userInfo?.manicurePedicure} name='manicurePedicure' />
                                            <p>
                                                Manicure, Pedicure
                                            </p>
                                        </div>
                                    </div>  
                                </div>
                                {/* <div className='inp-holder'>
                                    <label>
                                        Age*
                                    </label>
                                    <input required type='number' min={18} placeholder='18+' value={userInfo?.age} name='age' onChange={handleChange} />  
                                </div> */}
                                {/* <div className='inp-holder'>
                                    <label>
                                        Role*
                                    </label>
                                    <div className='roles-holder'>
                                        {
                                            roles.map((role, index)=>{
                                                return (
                                                    <span className={`role-item ${role === userInfo?.selectedRole ? "active" : ""}`} key={index} onClick={()=>{
                                                        setUserInfo((prev)=>{
                                                            return ({
                                                                ...prev,
                                                                selectedRole: role,
                                                            })
                                                        })
                                                    }}>
                                                        {role}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div> */}
                                <div className='inp-holder'>
                                    <label>
                                        Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.password} name='password' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Confirm Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.cpassword} name='cpassword' onChange={handleChange} />  
                                </div>
                                {error && <p style={{
                                    color: "red",
                                    marginTop: "10px",
                                }}>
                                    {error}
                                </p>}
                                <div className='btn-holder centered full-w' style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <button to={type?.linkTo} className='btn greenbutton'>
                                        Sign Up <IoIosPersonAdd />
                                    </button>
                                </div>
                            </form>}
                            {selectedDetails?.id === "agency" && <form className='form' onSubmit={handleSubmit}>
                                <p style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}>
                                    Fields marked with * are mandatory
                                </p>
                                <div className='inp-holder'>
                                    <label>
                                        Email*
                                    </label>
                                    <input required placeholder='email@address.com' value={userInfo?.email} name='email' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        First Name*
                                    </label>
                                    <input required placeholder='John' value={userInfo?.firstName} name='firstName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Last Name*
                                    </label>
                                    <input required placeholder='Doe' value={userInfo?.lastName} name='lastName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Display Name*
                                    </label>
                                    <input required placeholder='Johnny Doe' value={userInfo?.name} name='name' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Age*
                                    </label>
                                    <input required type='number' min={18} placeholder='18+' value={userInfo?.age} name='age' onChange={handleChange} />  
                                </div>
                                {/* <div className='inp-holder'>
                                    <label>
                                        Role*
                                    </label>
                                    <div className='roles-holder'>
                                        {
                                            roles.map((role, index)=>{
                                                return (
                                                    <span className={`role-item ${role === userInfo?.selectedRole ? "active" : ""}`} key={index} onClick={()=>{
                                                        setUserInfo((prev)=>{
                                                            return ({
                                                                ...prev,
                                                                selectedRole: role,
                                                            })
                                                        })
                                                    }}>
                                                        {role}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div> */}
                                <div className='inp-holder'>
                                    <label>
                                        Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.password} name='password' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Confirm Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.cpassword} name='cpassword' onChange={handleChange} />  
                                </div>
                                {error && <p style={{
                                    color: "red",
                                    marginTop: "10px",
                                }}>
                                    {error}
                                </p>}
                                <div className='btn-holder centered full-w' style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <button to={type?.linkTo} className='btn greenbutton'>
                                        Sign Up <IoIosPersonAdd />
                                    </button>
                                </div>
                            </form>}
                            {selectedDetails?.id === "user" && <form className='form' onSubmit={handleSubmit}>
                                <p style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}>
                                    Fields marked with * are mandatory
                                </p>
                                <div className='inp-holder'>
                                    <label>
                                        Email*
                                    </label>
                                    <input required placeholder='email@address.com' value={userInfo?.email} name='email' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        First Name*
                                    </label>
                                    <input required placeholder='John' value={userInfo?.firstName} name='firstName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Last Name*
                                    </label>
                                    <input required placeholder='Doe' value={userInfo?.lastName} name='lastName' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Display Name*
                                    </label>
                                    <input required placeholder='Johnny Doe' value={userInfo?.name} name='name' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Age*
                                    </label>
                                    <input required type='number' min={18} placeholder='18+' value={userInfo?.age} name='age' onChange={handleChange} />  
                                </div>
                                {/* <div className='inp-holder'>
                                    <label>
                                        Role*
                                    </label>
                                    <div className='roles-holder'>
                                        {
                                            roles.map((role, index)=>{
                                                return (
                                                    <span className={`role-item ${role === userInfo?.selectedRole ? "active" : ""}`} key={index} onClick={()=>{
                                                        setUserInfo((prev)=>{
                                                            return ({
                                                                ...prev,
                                                                selectedRole: role,
                                                            })
                                                        })
                                                    }}>
                                                        {role}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div> */}
                                <div className='inp-holder'>
                                    <label>
                                        Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.password} name='password' onChange={handleChange} />  
                                </div>
                                <div className='inp-holder'>
                                    <label>
                                        Confirm Password*
                                    </label>
                                    <input required placeholder='********' value={userInfo?.cpassword} name='cpassword' onChange={handleChange} />  
                                </div>
                                {error && <p style={{
                                    color: "red",
                                    marginTop: "10px",
                                }}>
                                    {error}
                                </p>}
                                <div className='btn-holder centered full-w' style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <button to={type?.linkTo} className='btn greenbutton'>
                                        Sign Up <IoIosPersonAdd />
                                    </button>
                                </div>
                            </form>}
                        </>
                    : 
                        <>
                            {
                                registrationTypes?.map((type, index)=>{
                                    return (
                                        <section className='auth-section' key={index}>
                                            <div className='heading'>
                                                <h3>{type?.title}</h3>
                                            </div>
                                            <div className='features'>
                                                {type?.features?.map((feature, index)=>{
                                                    return (<div className='feature' key={index}>
                                                        <FaCheck />
                                                        <p>
                                                            {feature}
                                                        </p>
                                                    </div>)
                                                })}
                                            </div>
                                            {type?.price && <div className='more-info'>
                                                <p>
                                                    {type?.price}
                                                </p>
                                            </div>}
                                            <div className='btn-holder'>
                                                <button onClick={()=>{
                                                    setSelected(true)
                                                    setSelectedDetails(type)
                                                }} className='btn greenbutton'>
                                                    Register here <FaChevronRight />
                                                </button>
                                            </div>
                                        </section>
                                    )
                                })
                            }
                        </>
                    }
                    
                </section>
                <div className='actions'>
                    <Link style={{
                        color: "#666",
                        fontSize: "14px"
                    }} to={"/login"}>
                        Already have an Account? Login Instead
                    </Link>
                </div>
                {appState.isLoggedIn && <p style={{
                    marginTop: "20px",
                }}>
                    You're already signed in as <Link to={"/"}><span>{currentUser?.email}</span></Link>
                </p>}
            </div>
            <Footer />
        </div>
    </>
  )
}

export default RegisterEscort