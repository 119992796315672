import React, { useState } from 'react'
import Footer from '../../../components/app/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
// import { useAuth } from '../../../context/AuthContext';
// import { doc, setDoc } from 'firebase/firestore';

const SignIn = ({appState, setAppState}) => {
    const [ userInfo, setUserInfo ] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: "",
        selectedRole: "Visitor"
    })

    const navigate = useNavigate()

    const registrationTypes = [
        {
            title:"Register as an Escort",
            features: [
                "Add a single profile",
                "Add profile pictures",
                "Add gallery pictures",
                "Add contact information",
                "Post classified ads",
                "Generate a shareable link to profile",
            ],
            linkTo: "/register/escort",
        },
        {
            title:"Register as a Masseuse",
            features: [
                "Add a single profile",
                "Add gallery, workspace pictures",
                "Generate a shareable link to profile",
                "Add profile pictures",
                "Add contact information",
                "Add blacklisted clients",
            ],
            linkTo: "/register/massage",
            // moreInfo: "for 2 weeks",
        },
    ]
    const type = registrationTypes[0]

    const [error, setError] = useState("")

    const handleChange = (e)=>{
        const {name, value} = e.target
        console.log(e.target.name)
        setUserInfo((prev)=>{
            return (
                {
                    ...prev,
                    [name]: value
                }
            )
        })
        try{
            
        } catch(error){
            setError(error?.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        const {password, email} = userInfo
        try {
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: true,
                    }
                )
            })
            await signInWithEmailAndPassword(auth, email, password);
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: false,
                    }
                )
            })
            console.log("Signed In")
            navigate('/');
        } catch (error) {
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: false,
                    }
                )
            })
            setError(error.message)
            console.error(error)
        }
    };

    return (
    <>
        <div className='page register--page no-bg centered'>
            <div className='container centered' style={{
                marginTop: "20px",
            }}>
                <section className='auth-section'>
                    <div className='heading'>
                        <h2>{"SignIn"}</h2>
                    </div>
                    {type?.moreInfo && <div className='more-info'>
                        <p>
                            {type?.moreInfo}
                        </p>
                    </div>}
                    <form className='form' onSubmit={handleSubmit}>
                        <div className='inp-holder'>
                            <label>
                                Email*
                            </label>
                            <input required placeholder='email@address.com' value={userInfo?.email} name='email' onChange={handleChange} />  
                        </div>
                        <div className='inp-holder'>
                            <label>
                                Password*
                            </label>
                            <input required type='password' placeholder='********' value={userInfo?.password} name='password' onChange={handleChange} />  
                        </div>
                        {error && <p style={{
                            color: "red",
                            marginTop: "10px",
                        }}>
                            {error}
                        </p>}
                        <div className='btn-holder centered full-w' style={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <button to={type?.linkTo} className='btn greenbutton'>
                                Sign In
                            </button>
                        </div>
                    </form>
                </section>
                <div className='actions'>
                    <Link style={{
                        color: "#666",
                        fontSize: "14px",
                        textTransform: "none",
                    }} to={"/register"}>
                        Create an Account
                    </Link>
                    {/* <Link style={{
                        color: "#666",
                        fontSize: "14px"
                    }} to={"/help"}>
                        Help
                    </Link> */}
                </div>
            </div>
            <Footer />
        </div>
    </>
  )
}

export default SignIn