import React from 'react'

const VerifyAge = ({universalComponents, appState}) => {
    const verifyAgeSuccess = ()=>{
      universalComponents?.functions?.verifyAge()
    }
    return (
    <>
        <div className='component full-h full-w default-gradient verify--age fixed center-items page-padding'>
              <div className='component card--container center-items'>
                <h4>AGE VERIFICATION</h4>
                <div className="logo-holder"><img className='invert grayscale' src={universalComponents?.logo} alt="" draggable="false" /></div> <p>This website may contain nudity and sexuality, and is intended for a mature audience.</p>
                <p>You must be 18 or older to enter.</p>
                <div className="clear20"></div>
                <div className="btn-holder center-items">
                <div className="btn greenbutton hover" onClick={verifyAgeSuccess}>I'm 18 or older</div>
                <a className="btn redbutton hover" href='www.google.com'>Leave</a>
                </div>
                <div className="clear20"></div>
              </div>
        </div>
    </>
  )
}

export default VerifyAge