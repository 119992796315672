import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import EscortCard from '../../components/cards/EscortCard'
import FixedEl from '../../components/FixedEl'
import Select from 'react-select';
import { Country, State, City } from 'country-state-city'
import { FaChevronRight, FaChevronUp } from 'react-icons/fa';
import Footer from '../../components/app/Footer';
import { Link } from 'react-router-dom';

const Home = ({thisUi, appState, selected, users, setSelected}) => {
  const [hasSet, setHasSet] = useState(false)
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)

  const usersRole = users.filter((user)=>{
    const typeLower = selected?.toLowerCase()
    const serviceLower = user?.accountType?.toLowerCase()
    return typeLower && serviceLower !== "user"
  })

  const filteredUsersCountries = usersRole?.filter((user)=>{
    const countryLower = selectedCountry?.label?.toLowerCase()
    const userCountryLower = user?.location?.country?.toLowerCase()
    const stateLower = selectedState?.label?.toLowerCase()
    const userStateLower = user?.location?.state?.toLowerCase()
    const cityLower = selectedCity?.label?.toLowerCase()
    const userCityLower = user?.location?.city?.toLowerCase()
    const isLocationMatch = ({userCountryLower, userStateLower, userCityLower, countryLower, stateLower, cityLower}) => {
      // If city information is available, compare all three: country, state, and city
      if (cityLower) {
          return userCountryLower === countryLower && userStateLower === stateLower && userCityLower === cityLower;
      }
  
      // If only country and state information is available, compare those
      if (stateLower) {
          return userCountryLower === countryLower && userStateLower === stateLower;
      }
  
      // If only country information is available, compare country
      if (countryLower) {
          return userCountryLower === countryLower;
      }
  
      // If no user location information is available, return a default or false
      return true;
  };
  
    const result = isLocationMatch({userCountryLower, userStateLower, userCityLower, countryLower, stateLower, cityLower})
    return result
  })

  const vipUsers = filteredUsersCountries?.filter((user)=>{
    return user?.isVip
  })

  const premiumUsers = filteredUsersCountries?.filter((user)=>{
    return user?.isPremium
  })

  const otherUsers = filteredUsersCountries?.filter((user)=>{
    return !user?.isPremium && !user?.isVip
  })

  const slicedName = appState?.user?.name?.slice(0, 7) || "Guest"
  
  useEffect(() => {
    const loadCountries = () => {
      setCountries(Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name })));
    };
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry ) {
      setStates(State.getStatesOfCountry(selectedCountry?.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })));
      if(hasSet){
        setSelectedState(null); // Reset state selection when country changes
      }
    }
  }, [selectedCountry, hasSet]);
  
  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry.value, selectedState.value).map(({ name }) => ({ value: name, label: name })));
      setSelectedCity(null); // Reset city selection when state changes
      if(hasSet){
        setSelectedCity(null); // Reset state selection when country changes
      }
    }
  }, [selectedState, selectedCountry, hasSet]);

  const pageRef = useRef(null)

  const scrollTop = ()=>{
    pageRef?.current?.scrollTo(0, 0)
  }

  
  const [showScrollTop, setScrollTop] = useState(false)

  useLayoutEffect(()=>{
    const page = pageRef?.current
    // const page = document.getElementById("page")
    function checkScroll(){
      if(page?.scrollTop && page?.scrollTop > 100){
        setScrollTop(true)
      } else{
        setScrollTop(false)
      }
    }

    if(page){
      page.addEventListener("scroll", checkScroll)
    }
  }, [])

  const selectors = [
    {
      title: "All",
      setTo: "",
    },
    {
      title: "Escorts",
      setTo: "escort",
    },
    {
      title: "Massage",
      setTo: "masseuse",
    },
  ]

  const headSection = useRef(null)

  const gotoHead = ()=>{
    headSection?.current?.scrollIntoView()
  }


  return (
    <>
        <div className='page home--page' ref={pageRef}>
            <div className={`scroll-to-top ${showScrollTop ? "show" : ""}`} onClick={scrollTop}>
              <FaChevronUp />
            </div>
            <div className='container'>
              <section className='intro--section' style={{
                maxHeight: "500px",
              }}>
                <div className='auth'>
                  {appState?.isLoggedIn ? <>
                  {
                    <p>
                      Signed in as <Link to={"/profile"}>{`${appState?.user?.name?.length > 7 ? `${slicedName || "..."}...` : (appState?.user?.name || "...")}`}</Link>
                    </p>
                  }
                  </> :
                  <Link to={"/register"}>
                    Get Started Here <FaChevronRight />
                  </Link>}
                </div>
                <p>
                  Welcome to
                </p>
                <h1>
                  Massage & X
                </h1>
                <p className='small'>
                  Home to Exquisite Escorts and Massage services
                </p>
                <div className='btn-holderx'>
                  <button className='explore btn' onClick={gotoHead}>
                    Explore
                  </button>
                </div>
              </section>
              <section className='head-section'>
              <div className='headsec' ref={headSection}></div>
              <h2>
                Quick Search
              </h2>
              <div className='selector' style={{
                display: "none"
              }}>
                {selectors?.map((selector, index)=>{
                  return (
                    <span key={index} className={`select ${selected === selector?.setTo ? "active" : ""}`} onClick={()=>{
                      setSelected(selector?.setTo)
                    }}>{selector?.title}</span>
                  )
                })}
              </div>
              <h3>Escort Country List </h3>
              <div className='holder'>
                <div className='inp-holder'>
                  <label>Country</label>
                  <div className='select-holder'>
                      <Select
                          options={countries}
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          placeholder="Select Country"
                          setHasSet={setHasSet}
                      />
                  </div>
                </div>
                <div className='inp-holder'>
                    <label>State</label>
                    <div className='select-holder'>
                        <Select
                            options={states}
                            value={selectedState}
                            onChange={setSelectedState}
                            placeholder="Select State"
                            isDisabled={!selectedCountry}
                        />
                    </div>
                </div>
                <div className='inp-holder'>
                    <label>City</label>
                    <div className='select-holder'>
                        <Select
                            options={cities}
                            value={selectedCity}
                            onChange={setSelectedCity}
                            placeholder="Select City"
                            isDisabled={!selectedState}
                        />
                    </div>
                </div>
              </div>
              </section>
              {vipUsers?.length > 0 && <section>
                <FixedEl element={"peach"} />
                <FixedEl element={"eggplant"} />
                <h2>Vip {selected || "Members"}</h2>
                <div className='cards-holder'>
                  {vipUsers?.map((user, index)=>{
                    return (
                      <EscortCard isPremium={true} key={index} user={user} appState={appState} />
                    )
                  })}
                </div>
              </section>}
              {premiumUsers?.length > 0 && <section>
                <h2>Premium {selected || "Members"}</h2>
                <div className='cards-holder'>
                  {premiumUsers?.map((user, index)=>{
                    return (
                      <EscortCard appState={appState} isPremium={true} key={index} user={user} />
                    )
                  })}
                </div>
              </section>}
              {otherUsers?.length > 0 && <section>
                <h2>All {selected || "Members"} </h2>
                <div className='cards-holder'>
                  {otherUsers?.map((user, index)=>{
                    return (
                      <EscortCard appState={appState} isPremium={true} key={index} user={user} />
                    )
                  })}
                </div>
              </section>}
              <Footer />
            </div>
        </div>
    </>
  )
}

export default Home