import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FaArrowRight, FaChevronUp, FaPlus, FaTrash, FaUser } from 'react-icons/fa';
import Footer from '../../components/app/Footer';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city'
import { auth, firestore, storage } from '../../firebaseConfig'; // Adjust this import according to your project structure
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const UpdateUserProfile = ({thisUi, appState, setAppState, selected, users, setSelected}) => {

    const pageRef = useRef(null)

  const scrollTop = ()=>{
    pageRef?.current?.scrollTo(0, 0)
  }


  
  const [showScrollTop, setScrollTop] = useState(false)
  const [error, setError] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [hasSet, setHasSet] = useState(false)
//   const [newService, setNewService] = useState("")

  useLayoutEffect(()=>{
    const page = pageRef?.current
    // const page = document.getElementById("page")
    function checkScroll(){
      if(page?.scrollTop && page?.scrollTop > 100){
        setScrollTop(true)
      } else{
        setScrollTop(false)
      }
    }

    if(page){
      page.addEventListener("scroll", checkScroll)
    }
  }, [])

  useEffect(() => {
    const loadCountries = () => {
      setCountries(Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name })));
    };
    loadCountries();
  }, []);

//   const roles = [
//     "Visitor",
//     "Escort",
//     "Massage",
//     "Escort & Massage",
// ]

  useEffect(() => {
    if (selectedCountry ) {
      setStates(State.getStatesOfCountry(selectedCountry?.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })));
      if(hasSet){
        setSelectedState(null); // Reset state selection when country changes
      }
    }
  }, [selectedCountry, hasSet]);
  
  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry.value, selectedState.value).map(({ name }) => ({ value: name, label: name })));
      setSelectedCity(null); // Reset city selection when state changes
      if(hasSet){
        setSelectedCity(null); // Reset state selection when country changes
      }
    }
  }, [selectedState, selectedCountry, hasSet]);

  const [ userInfo, setUserInfo ] = useState({
    selectedRole: "Visitor",
  })
  const [ selectedImage, setSelectedImage ] = useState({
    file: "",
    src: ""
  })
  const [ pic1Url, setPic1Url ] = useState(null)
  const [ pic2Url, setPic2Url ] = useState(null)
  const [ pic3Url, setPic3Url ] = useState(null)
  const handleChange = (e)=>{
    const {name, value} = e.target
    const type = e?.target?.type
    if(type === "checkbox"){
        const checked = e?.target?.checked
        setUserInfo((prev)=>{
            return (
                {
                    ...prev,
                    [name]: checked
                }
            )
        })
    } else{
        setUserInfo((prev)=>{
            return (
                {
                    ...prev,
                    [name]: value
                }
            )
        })
    }
  }

  const handleImageChange = (e)=>{
    const file = e?.target?.files[0]
    if (file.type.match('image.*')) {
        const reader = new FileReader();

        reader.onload = (event) => {
            const src = event.target.result;
            setSelectedImage({
                file,
                src,
            })
        };

        reader.readAsDataURL(file);
    } else {
        // Handle the case when the file is not an image
        alert('Please select an image file.');
        setSelectedImage({
            file: "",
            src: "",
        });
    }
  }

  useEffect(()=>{
        setUserInfo({
            ...appState?.user
        })
        // const convertUrlToFile = async (imageUrl) => {
        //     try {
        //         const response = await fetch(imageUrl);
        //         const blob = await response.blob();
        //         const file = new File([blob], "image.jpg", { type: blob.type });
                
        //         return file
        //     } catch (error) {
        //         console.error('Error fetching and converting image:', error);
        //     }
        // };
        if(appState?.user?.imgUrl){
            const asyncFunc = async()=>{
                    setSelectedImage({
                        file: "",
                        src: appState?.user?.imgUrl,
                    })
            }

            asyncFunc()
        
        }
        if(appState?.user?.gallery?.pic1Url){
            const asyncFunc = async ()=>{
                    setPic1Url({
                        file: "",
                        src: appState?.user?.gallery?.pic1Url,
                    })
            }

            asyncFunc()
        }

        if(appState?.user?.gallery?.pic2Url){
            const asyncFunc = async()=>{
                    setPic2Url({
                        file: "",
                        src: appState?.user?.gallery?.pic2Url,
                    })
            }

            asyncFunc()
        }

        if(appState?.user?.gallery?.pic3Url){
            const asyncFunc = async ()=>{
                    setPic3Url({
                        file: "",
                        src: appState?.user?.gallery?.pic3Url,
                    })
            }

            asyncFunc()
        }
  },[appState.user])

//   const addService = (value)=>{
//     const prevServices = userInfo?.services || []
//     const newServices = [
//         ...prevServices,
//         value
//     ]
//     if(prevServices?.includes(value)){
//         alert("You've already added that service")
//         return
//     } else{
//         if(value){
//             setUserInfo((prev)=>{
//                 return ({
//                     ...prev,
//                     services: [
//                         ...newServices
//                     ],
//                 })
//             })
//         }
//     }
//   }

//   const deleteService = (value)=>{
//     const prevServices = userInfo?.services || []
//     if(prevServices){
//         const newServices = prevServices?.filter((service)=>{
//             return service !== value
//         })
//         setUserInfo((prev)=>{
//             return ({
//                 ...prev,
//                 services: [
//                     ...newServices
//                 ],
//             })
//         })
//     }
//   }

  const user = userInfo

  const removeImage = ()=>{
    setSelectedImage(null)
  }

  const handleSubmit = async(e)=>{
    e.preventDefault()
    const {uid} = userInfo
    const location = {
        country: selectedCountry?.label,
        state: selectedState?.label,
        city: selectedCity?.label,
    }
    const details = {
        ...userInfo,
        location,
    }

    console.log(uid)
    if(location?.country && location?.state){
        try{
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: true,
                    }
                )
            })
            let profileImageUrl = '';
            if (selectedImage?.file) {
                const imageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
                await uploadBytes(imageRef, selectedImage?.file);
                profileImageUrl = await getDownloadURL(imageRef);
            } else{
                profileImageUrl = selectedImage?.src
            }

            let galleryI = '';
            if (pic1Url?.file) {
                const imageRef = ref(storage, `pic1Urls/${auth.currentUser.uid}`);
                await uploadBytes(imageRef, pic1Url?.file);
                galleryI = await getDownloadURL(imageRef);
            } else{
                galleryI = pic1Url?.src
            }

            let galleryII = '';
            if (pic2Url?.file) {
                const imageRef = ref(storage, `pic2Urls/${auth.currentUser.uid}`);
                await uploadBytes(imageRef, pic2Url?.file);
                galleryII = await getDownloadURL(imageRef);
            } else{
                galleryII = pic2Url?.src
            }

            let galleryIII = '';
            if (pic3Url?.file) {
                const imageRef = ref(storage, `pic3Urls/${auth.currentUser.uid}`);
                await uploadBytes(imageRef, pic3Url?.file);
                galleryIII = await getDownloadURL(imageRef);
            } else{
                galleryIII = pic3Url?.src
            }

            const userDoc = doc(firestore, 'users', auth.currentUser.uid);
            await updateDoc(userDoc, {
                ...details,
                ...(profileImageUrl && { imgUrl: profileImageUrl }),
                gallery: {
                    pic1Url: galleryI || "",
                    pic2Url: galleryII || "",
                    pic3Url: galleryIII || "",
                }
            });
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: false,
                    }
                )
            })
            navigate("/profile")
        } catch(error){
            console.error(error)
            setError(error?.message)
            setAppState((prev)=>{
                return(
                    {
                        ...prev,
                        isLoading: false,
                    }
                )
            })
        }
    } else{
        scrollTop()
        setError("Please include your Location Details")
    }
  }

  return (
    <>
        <div className='page profile--page home--page' ref={pageRef}>
            <div className={`scroll-to-top ${showScrollTop ? "show" : ""}`} onClick={scrollTop}>
              <FaChevronUp />
            </div>
            <form className='container' onSubmit={handleSubmit}>
                <section className='head-section'>
                    <h2>
                        Update Profile
                    </h2>
                </section>
                {error &&
                    <p style={{color: "red", margin: "20px 0"}}>
                        {error}
                    </p>
                }
                <section className='profile--section' style={{
                    marginTop: "-30px",
                }}>
                    <h3>Profile Photo</h3>
                    <div className='spread' style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "30px",
                    }}>
                    <div className='img-holder'  style={{
                            width: "100px",
                            height: "100px"
                        }}>
                        {user?.imgUrl ? <img width={200} src={appState?.user?.imgUrl} alt='' />
                        : <FaUser style={{
                            fontSize: "40px"
                        }} />}
                    </div>
                    {selectedImage?.file && <>
                        <FaArrowRight />
                        <div className='img-holder'  style={{
                            width: "100px",
                            height: "100px"
                        }}>
                            <img width={200} src={selectedImage?.src} alt='' />
                        </div>
                    </>}
                    </div>
                    <div className='btn-holder' style={{
                        margin: "0",
                        border: "none",
                        padding: "0",
                        marginTop: "10px",
                        marginLeft: "10px"
                    }}>
                        {!selectedImage?.file ? <label htmlFor='profileImg' className='btn'>
                            Add Image
                        </label> : <label className='btn' onClick={removeImage}>
                            Remove Image
                        </label>}
                        <input style={{
                            display: "none",
                            pointerEvents: "none"
                        }} onChange={handleImageChange} type="file" accept="image/*" id='profileImg' />
                    </div>
                </section>
                {user?.accountType !== "user" && <section className='profile--section'>
                    <h3>Gallery</h3>
                    <div className='gallery-img-holder'>
                        <label htmlFor={!pic1Url?.file ? 'pic1Url' : ""} className='gallery-img' onClick={()=>{
                            
                        }}>
                            <input id='pic1Url' type="file" accept="image/*" style={{
                                display: "none",
                                pointerEvents: "none"
                            }} onChange={(e)=>{
                                const file = e.target?.files[0]
                                if (file?.type?.match('image.*')) {
                                    const reader = new FileReader();

                                    reader.onload = (event) => {
                                        const src = event.target.result;
                                        setPic1Url({
                                            file,
                                            src,
                                        })
                                    };

                                    reader.readAsDataURL(file);
                                } else {
                                    // Handle the case when the file is not an image
                                    setPic1Url({
                                        file: "",
                                        src: "",
                                    });
                                }
                            }} />
                            {pic1Url?.src ? <img width={200} src={pic1Url?.src} alt='' /> : <FaPlus />}
                            {pic1Url?.src && <p className='del' onClick={(e)=>{
                                e.stopPropagation()
                                setPic1Url({
                                    file: "",
                                    src: ""
                                })
                            }}>
                                <FaTrash />
                            </p>}
                        </label>
                        <label htmlFor={!pic2Url?.file ? 'pic2Url' : ""} className='gallery-img' onClick={()=>{
                            
                        }}>
                            <input id='pic2Url' type="file" accept="image/*" style={{
                                display: "none",
                                pointerEvents: "none"
                            }} onChange={(e)=>{
                                const file = e?.target?.files[0]
                                if (file?.type?.match('image.*')) {
                                    const reader = new FileReader();

                                    reader.onload = (event) => {
                                        const src = event.target.result;
                                        setPic2Url({
                                            file,
                                            src,
                                        })
                                    };

                                    reader.readAsDataURL(file);
                                } else {
                                    // Handle the case when the file is not an image
                                    setPic2Url({
                                        file: "",
                                        src: "",
                                    });
                                }
                            }} />
                            {pic2Url?.src ? <img width={200} src={pic2Url?.src} alt='' /> : <FaPlus />}
                            {pic2Url?.src && <p className='del' onClick={(e)=>{
                                e.stopPropagation()
                                setPic2Url({
                                    file: "",
                                    src: ""
                                })
                            }}>
                                <FaTrash />
                            </p>}
                        </label>
                        <label htmlFor={!pic3Url?.file ? 'pic3Url' : ""} className='gallery-img' onClick={()=>{
                            
                        }}>
                            <input id='pic3Url' type="file" accept="image/*" style={{
                                display: "none",
                                pointerEvents: "none"
                            }} onChange={(e)=>{
                                const file = e.target?.files[0]
                                if (file?.type?.match('image.*')) {
                                    const reader = new FileReader();

                                    reader.onload = (event) => {
                                        const src = event.target.result;
                                        setPic3Url({
                                            file,
                                            src,
                                        })
                                    };

                                    reader.readAsDataURL(file);
                                } else {
                                    // Handle the case when the file is not an image
                                    setPic3Url({
                                        file: "",
                                        src: "",
                                    });
                                }
                            }} />
                            {pic3Url?.src ? <img width={200} src={pic3Url?.src} alt='' /> : <FaPlus />}
                            {pic3Url?.src && <p className='del' onClick={(e)=>{
                                e.stopPropagation()
                                setPic3Url({
                                    file: "",
                                    src: ""
                                })
                            }}>
                                <FaTrash />
                            </p>}
                        </label>
                    </div>
                </section>}
                <p style={{
                    marginTop: "30px",
                    color:"#666"
                }}>
                    <i>* means important</i>
                </p>
                <section className='profile--section'>
                    <h3>
                        My Details
                    </h3>
                    <div className='detail'>
                        <label>
                            Display Name*
                        </label>
                        <input required
                            placeholder="required" onChange={handleChange} name='name' value={user?.name}
                        />
                    </div>
                    <div className='detail'>
                        <label>
                            First Name*
                        </label>
                        <input required
                            placeholder="required" onChange={handleChange} name='firstName' value={user?.firstName}
                        />
                    </div>
                    <div className='detail'>
                        <label>
                            Last Name*
                        </label>
                        <input required
                            placeholder="required" onChange={handleChange} name='lastName' value={user?.lastName}
                        />
                    </div>
                    <div className='detail'>
                        <label>
                            Age*
                        </label>
                        <input required
                            placeholder="required" onChange={handleChange} name='age' value={user?.age}
                        />
                    </div>
                    <div className='detail'>
                        <label>
                            Phone Number (Whatsapp Enabled)*
                        </label>
                        <input required
                            placeholder="include country code" onChange={handleChange} name='phoneNo' type="tel" value={user?.phoneNo}
                        />
                    </div>
                    <div className='detail'>
                        <label>
                            Bio*
                        </label>
                        <input required
                            placeholder="Tell us about yourself, preferences etc." onChange={handleChange} name='bio' value={userInfo?.bio} minLength={5} maxLength={150}
                        />
                    </div>
                </section>
                <section className='profile--section'>
                    <h3>
                        Location
                    </h3>
                    <div className='location-holder'>

                    <div>
              <label htmlFor="password">Country*</label>
              <div className='select-holder'>
                  <Select
                      options={countries}
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      placeholder="Select Country"
                      setHasSet={setHasSet}
                  />
              </div>
          </div>
          <div>
              <label htmlFor="state">State*</label>
              <div className='select-holder'>
                  <Select
                      options={states}
                      value={selectedState}
                      onChange={setSelectedState}
                      placeholder="Select State"
                      isDisabled={!selectedCountry}
                  />
              </div>
          </div>
          <div>
              <label htmlFor="city">City</label>
              <div className='select-holder'>
                  <Select
                      options={cities}
                      value={selectedCity}
                      onChange={setSelectedCity}
                      placeholder="Select City"
                      isDisabled={!selectedState}
                  />
              </div>
          </div>
                    </div>
                </section>
                {user?.accountType !== "user" && <>
                    <section className='profile--section'>
                        <h3>
                            Services
                        </h3>
                        <div className='selector services'>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.eroticTantricMassage} name='eroticTantricMassage' />
                                                <p>
                                                    Erotic Tantric massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.deepTissueMassageTherapy} name='deepTissueMassageTherapy' />
                                                <p>
                                                    Deep Tissue Massage Theraoy
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.thaiMassage} name='thaiMassage' />
                                                <p>
                                                    Thai Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.massageSex} name='massageSex' />
                                                <p>
                                                    Massage, sex / extras / happy ending 
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.webcamSex} name='webcamSex' />
                                                <p>
                                                    Webcam sex (phone sex, video call) 
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.dfk} name='dfk' />
                                                <p>
                                                    DFK (Deep french kissing)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.oLevel} name='oLevel' />
                                                <p>
                                                    O-Level (Oral Sex)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.cob} name='cob' />
                                                <p>
                                                    COB (Come on body)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.lt} name='lt' />
                                                <p>
                                                    LT (Long Time; Usually overnight, sleep over)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.stripTease} name='stripTease' />
                                                <p>
                                                    Striptease/Lapdance
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.couples} name='couples' />
                                                <p>
                                                    Couples
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.teaBagging} name='teaBagging' />
                                                <p>
                                                    Teabagging
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.sixNine} name='sixNine' />
                                                <p>
                                                    (69 sex position/Reverse oral)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.vaginalSex} name='vaginalSex' />
                                                <p>
                                                    Vaginal Sex/pussy fuck
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.cof} name='cof' />
                                                <p>
                                                    COF (Come on face)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.deepThroat} name='deepThroat' />
                                                <p>
                                                    Deep Throat
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.squirting} name='squirting' />
                                                <p>
                                                    Squirting
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.straponDildo} name='straponDildo' />
                                                <p>
                                                    Strapon dildo
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.cim} name='cim' />
                                                <p>
                                                    CIM (come in mouth)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.analRimming} name='analRimming' />
                                                <p>
                                                    Anal Rimming (Licking anus)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.doublePenetration} name='doublePenetration' />
                                                <p>
                                                    Double Penetration
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.nuruMassage} name='nuruMassage' />
                                                <p>
                                                    Full Body to Body Nuru massage - Nude
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.swedishMassage} name='swedishMassage' />
                                                <p>
                                                    Swedish Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.professionalMassage} name='professionalMassage' />
                                                <p>
                                                    Professional Massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.hotStoneMassaage} name='hotStoneMassaage' />
                                                <p>
                                                    Hot stone massage
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.romanticDinner} name='romanticDinner' />
                                                <p>
                                                    Romantic Dinner dates
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.travellingCompanion} name='travellingCompanion' />
                                                <p>
                                                    Travelling Companion
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.handJob} name='handJob' />
                                                <p>
                                                    Hand Job
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.blowJob} name='blowJob' />
                                                <p>
                                                    Blow Job (bj)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.masturbation} name='masturbation' />
                                                <p>
                                                    Masturbation
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.cumOnBoobs} name='cumOnBoobs' />
                                                <p>
                                                    Cum on breasts/boobs/tits
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.extraBall} name='extraBall' />
                                                <p>
                                                    Extraball (Having sex multiple times)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.gfe} name='gfe' />
                                                <p>
                                                    GFE (Girlfriend experience)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.domination} name='domination' />
                                                <p>
                                                    Domination
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.domination} name='domination' />
                                                <p>
                                                    Domination
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.threesome} name='threesome' />
                                                <p>
                                                    Threesome
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.lesbianSex} name='lesbianSex' />
                                                <p>
                                                    Lesbian sex/show
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.sexParties} name='sexParties' />
                                                <p>
                                                    Attending corporate Parties / sex parties - group sex
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.owo} name='owo' />
                                                <p>
                                                    OWO (Oral without condom)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.faceSitting} name='faceSitting' />
                                                <p>
                                                    Face sitting
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.goldenShower} name='goldenShower' />
                                                <p>
                                                    Golden shower
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.sexToys} name='sexToys' />
                                                <p>
                                                    Sex Toys
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.fantasies} name='fantasies' />
                                                <p>
                                                    Fantasies & Costumes
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.footFetish} name='footFetish' />
                                                <p>
                                                    Foot fetish
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.bdsm} name='bdsm' />
                                                <p>
                                                    BDSM (Bondage/Discipline, Dominance/Submission and Sadism/Masochism)
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.pegging} name='pegging' />
                                                <p>
                                                    Pegging
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.aLevel} name='aLevel' />
                                                <p>
                                                    A-Level (Anal sex)/ Ass fuck
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.hardSports} name='hardSports' />
                                                <p>
                                                    Giving hardsports
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.rawSex} name='rawSex' />
                                                <p>
                                                    Raw Sex
                                                </p>
                                            </div>
                                            <div className='select'>
                                                <input type="checkbox" onChange={handleChange} checked={user?.manicurePedicure} name='manicurePedicure' />
                                                <p>
                                                    Manicure, Pedicure
                                                </p>
                                            </div>
                                        </div>
                    </section>
                    <section className='profile--section'>
                                        <label>
                                            Rates*
                                        </label>
                                        <p>
                                            Currency: <b>USD</b>
                                        </p>
                                        <div className='rates-holder'>
                                            <div className='top tab'>
                                                <div className='time'>
                                                    
                                                </div>
                                                <div className='time'>
                                                    Incall
                                                </div>
                                                <div className='time'>
                                                    Outcall
                                                </div>
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    30 minutes
                                                </div>
                                                <input className='incall' name='thirtyMinRateInCall' value={user?.thirtyMinRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='thirtyMinRateOutCall' value={user?.thirtyMinRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    1 hour
                                                </div>
                                                <input className='incall' name='oneHourRateInCall' value={user?.oneHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='oneHourRateOutCall' value={user?.oneHourRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    2 hours
                                                </div>
                                                <input className='incall' name='twoHourRateInCall' value={user?.twoHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='twoHourRateOutCall' value={user?.twoHourRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    3 hours
                                                </div>
                                                <input className='incall' name='threeHourRateInCall' value={user?.threeHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='threeHourRateOutCall' value={user?.threeHourRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    6 hours
                                                </div>
                                                <input className='incall' name='sixHourRateInCall' value={user?.sixHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='sixHourRateOutCall' value={user?.sixHourRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    12 hours
                                                </div>
                                                <input className='incall' name='twelveHourRateInCall' value={user?.twelveHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='twelveHourRateOutCall' value={user?.twelveHourRateOutCall} onChange={handleChange} />
                                            </div>
                                            <div className='content tab'>
                                                <div className='time'>
                                                    24 hours
                                                </div>
                                                <input className='incall' name='twentyFourHourRateInCall' value={user?.twentyFourHourRateInCall} onChange={handleChange} />
                                                <input className='outcall' name='twentyFourHourRateOutCall' value={user?.twentyFourHourRateOutCall} onChange={handleChange} />
                                            </div>
                                        </div>
                    </section>
                </>}
                <div className='btn-holder'>
                    <button className="btn" 
                    >
                        Save Changes
                    </button>
                </div>
                <Footer/>
            </form>
        </div>
    </>
  )
}

export default UpdateUserProfile