import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./styles/index.scss"
import { useEffect, useState } from "react";
import Home from "./pages/home/Home";
import NoAccess from "./components/app/NoAccess";
import VerifyAge from "./components/app/VerifyAge";
import logoImage from "./assets/logo.png"
import Header from "./components/app/Header";
import RegisterEscort from "./pages/home/auth/RegisterEscort";
import SignIn from "./pages/home/auth/SignIn";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "./context/AuthContext";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { auth, firestore } from "./firebaseConfig";
import { signOut } from "firebase/auth";
import Profile from "./pages/profile/Profile";
import UpdateUserProfile from "./pages/profile/UpdateProfile";
import UserProfile from "./pages/profile/UserProfile";

function App() {
  const navigate = useNavigate()
  const [appState, setAppState]= useState({
    ageVerified: true,
    depictedAge: null,
    isFirstTime: false,
    isLoadingPage: false,
    isLoading: false,
    isLoadingFunction: false,
    isLoggedIn: false,
    user:{
      uid: "",
      email: "",
      firstName: "",
      lastName: "",
      bio: "",
      age: "",
      whatsappNo: "",
      phoneNo: "",
      telegramId: "",
      location: {
        country: "",
        state: "",
        city: "",
      },
      imgUrl: "",
      coverVideo: "",
      gallery: {
        pic1Url: "",
        pic2Url: "",
        pic3Url: "",
      },
      isVip: false,
      isPremium: false,
      isAdmin: false,
      hasSubscribed: false,
      services: [
      ]
    },
    admin: {},
    users: [],
    functions: {
      logout: async () => {
        try {
          const confirm = window.confirm("Are you Sure you want to log out?")
          if(confirm){
            await signOut(auth);
            navigate("/login")
            console.log("Successfully logged out!");
          }
        } catch (error) {
          console.error("Error logging out: ", error);
          // Handle logout errors here (e.g., display error message)
        }
      },
    }
  
  })

  const [ thisUi, setThisUi ] = useState({
    showOnline: false,
    showOnlinePrompt: true,
  })

  const universalComponents = {
    logo: logoImage,
    links: {
      telegram: "https://telegram.org",
    },
    functions: {
      verifyAge: ()=>{
        localStorage?.setItem("verified--age", "true")
        setAppState((prev)=>{
          return ({
            ...prev,
            ageVerified: true,
            // depictedAge: null,
          })
        })
      }
    }
  }

  const location = useLocation()
  const currentPath = location?.pathname

  const { currentUser } = useAuth();

  useEffect(() => {
    if(appState.isLoggedIn){
      const fetchUserProfile = async () => {
        const userDoc = doc(firestore, 'users', auth?.currentUser?.uid);
        const docSnap = await getDoc(userDoc);
        
        if (docSnap.exists()) {
          const details = docSnap.data()
          let completedPercentage = "30"
          const location = details?.location
          if(details?.firstName && details?.lastName && location?.country && location?.state && details?.imgUrl){
            completedPercentage = "100"
          }
          setAppState((prev)=>{
            return ({
              ...prev,
              user:{
                ...prev?.user,
                ...details,
                calculatedPercent: completedPercentage,
              },
              uid: currentUser?.uid,
            })
          });
        } else {
          setAppState(()=>{
            return ({
              uid: "",
              email: "",
              firstName: "",
              lastName: "",
              bio: "",
              age: "",
              whatsappNo: "",
              phoneNo: "",
              telegramId: "",
              location: {
                country: "",
                state: "",
                city: "",
              },
              imgUrl: "",
              coverVideo: "",
              gallery: [
              ],
              isVip: true,
              isPremium: false,
              isAdmin: false,
              hasSubscribed: false,
              services: [
              ]
            })
          });
          console.log('No such document!');
        }
      };
      
      fetchUserProfile();
    } else{
      setAppState((prev)=>{
        return ({
          ...prev,
          user:{
            uid: "",
            email: "",
            firstName: "",
            lastName: "",
            bio: "",
            age: "",
            whatsappNo: "",
            phoneNo: "",
            telegramId: "",
            location: {
              country: "",
              state: "",
              city: "",
            },
            imgUrl: "",
            coverVideo: "",
            gallery: {
              pic1Url: "",
              pic2Url: "",
              pic3Url: "",
            },
            isVip: false,
            isPremium: false,
            isAdmin: false,
            hasSubscribed: false,
            services: [
            ]
          },
        })
      })
    }

    if(currentUser){
      setAppState((prev)=>{
        return ({
          ...prev,
          isLoggedIn: true
        })
      }) 
    } else{
      setAppState((prev)=>{
        return ({
          ...prev,
          isLoggedIn: false,
          user:{
            uid: "",
            email: "",
            firstName: "",
            lastName: "",
            bio: "",
            age: "",
            whatsappNo: "",
            phoneNo: "",
            telegramId: "",
            location: {
              country: "",
              state: "",
              city: "",
            },
            imgUrl: "",
            coverVideo: "",
            gallery: {
              pic1Url: "",
              pic2Url: "",
              pic3Url: "",
            },
            isVip: false,
            isPremium: false,
            isAdmin: false,
            hasSubscribed: false,
            services: [
            ]
          },
        })
      }) 
    }

    const fetchUsers = async () => {
      const prevArray = localStorage.getItem("users")
      const converted = JSON.parse(prevArray);

    if(converted){
      setAppState(prev => ({
        ...prev,
        users: converted,
        allUsers: converted,
      }));
    }
      const usersCollectionRef = collection(firestore, 'users');
      console.log("Fetching USers")
      const querySnapshot = await getDocs(usersCollectionRef);
      const usersArray = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }));

      // const filteredUsers = usersArray?.filter((user)=>{
      //   return user?.isVisible
      // })

      setAppState(prev => ({
        ...prev,
        users: usersArray,
        allUsers: usersArray,
      }));

      if(usersArray){
        const userArrayX = JSON.stringify(usersArray)
        localStorage.setItem("users", userArrayX)
      }
    };
    
    fetchUsers()

  }, [appState.isLoggedIn, currentPath, currentUser]);

  let [color, setColor] = useState("#ffffff");

  useEffect(()=>{
    const verifiedAge = localStorage?.getItem("verified--age")
    if(verifiedAge){
      setAppState((prev)=>{
        return ({
          ...prev,
          ageVerified: true,
        })
      })
    } else{
      localStorage?.removeItem("verified--age")
      setAppState((prev)=>{
        return ({
          ...prev,
          ageVerified: false,
        })
      })
    }
  },[])

  const [selected, setSelected ] = useState("escort")

  const {ageVerified, depictedAge} = appState

  return (
    <div className={`app ${appState?.ageVerified ? "hide-verify-age" : ""} ${thisUi.showOnlinePrompt ? "" : "remove-padding"}`}>

      {/* top level components */}
      <VerifyAge thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} universalComponents={universalComponents} />

      <Header selected={selected} setSelected={setSelected} thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} />

      {appState?.isLoading && <div className="preloader">
        <ClipLoader
          color={color}
          loading={true}
          // cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          setColor={setColor}
        />
      </div>}

      <Routes>

        <Route path={"/" || "/home"} element={ ageVerified ? ( !depictedAge ? <Home thisUi={thisUi} selected={selected} setSelected={setSelected} setThisUi={setThisUi} appState={appState} users={appState.users} /> : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />

        <Route path={"/user-profile/:userId"} element={ ageVerified ? ( !depictedAge ? (appState?.isLoggedIn !== "axkjhjhagjxajhx" ? <UserProfile
         thisUi={thisUi} selected={selected} setSelected={setSelected} setThisUi={setThisUi} appState={appState} setAppState={setAppState} users={appState.users} /> : <SignIn thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} />) : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />
        
        <Route path={"/profile" || "/my-profile"} element={ ageVerified ? ( !depictedAge ? (appState?.isLoggedIn ? <Profile
         thisUi={thisUi} selected={selected} setSelected={setSelected} setThisUi={setThisUi} appState={appState} setAppState={setAppState} users={appState.users} /> : <SignIn thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} />) : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />

        <Route path={"/update-profile" || "/my-profile"} element={ ageVerified ? ( !depictedAge ? (appState?.isLoggedIn ? <UpdateUserProfile
         thisUi={thisUi} selected={selected} setSelected={setSelected} setThisUi={setThisUi} appState={appState} setAppState={setAppState} users={appState.users} /> : <SignIn thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} />) : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />

        <Route path={"/register" || "/signup"} element={ ageVerified ? ( !depictedAge ? <RegisterEscort thisUi={thisUi} setThisUi={setThisUi}  appState={appState} setAppState={setAppState} /> : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />

        <Route path={"/login" || "/signup"} element={ ageVerified ? ( !depictedAge ? <SignIn thisUi={thisUi} setThisUi={setThisUi} appState={appState} setAppState={setAppState} /> : <NoAccess reason={"You must be 18 years old or older to use MassageandX.com"} code={"401"} action={"/verify-age"} /> ) : <></> } />
      
      </Routes>
    </div>
  );
}

export default App;
